.home-content {
    width: 100%;
}

.home-hero01 {
    width: 100%;
    height: 100vh;
    max-height: 797px;
    background-image: url('../../assets/hero01.jpg');
    background-size: cover;
    background-position: bottom;
}

.home-hero01-01 {
    width: 100%;
    height: 642px;
    background-image: url('../../assets/hero01-02.jpg');
    background-size: cover;
    background-position: center;
}

.home-services-container {
    width: 100%;
    text-align: center;
    padding: 32px 0;
    margin: auto;
    margin-top: 42px;
}

.home-services-item {
    display: flex;
    background-image: url('../../assets/home/services-bg.png');
    background-repeat: no-repeat;
    background-size: 190px 100%;
    background-position: right;
    padding: 32px 24px;
    align-items: center;
    justify-content: space-between;
}

.home-services-item-text {
    padding-right: 52px;
}

.home-services-item-text p {
    margin-bottom: 16px;
    margin-top: 10px;
}


.home-services-item img {
    width: 300px;
    height: 300px;
}

.home-slider {
    display: block;
}

.home-slider-sm {
    display: none;
}



.home-content-01 {
    margin-top: 82px;
    width: 100%;
    height: auto;
}

.home-content-01-section1 {
    width: 100%;
    display: flex;
}

.home-content-01-section1 img {
    position: absolute;
    margin-top: -20px;
    right: 0%;
    margin-right: 4%;
}

.home-content-01-section1-color {
    background: #B85617;
    width: 100%;
    height: 400px;
}

.home-content-01-section2 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-top: -280px;
}

.home-content-01-section2 p {
    background: #cf862f;
    opacity: 0.8;
    font-size: 18px;
    color: #fff;
    padding: 42px 18px;    
    margin-left: 12px;
    margin-right: 12%;
}

.home-location-container {
    display: flex;
    width:100%;
    align-items: center;
    background: #F4EEE1;
}

.home-location-text {
    padding: 50px 50px;
}


@media screen and (max-width: 1024px) {
    .home-hero01, .home-hero01-01{
        height: 442px !important;
        background-position: bottom;
    }

    .home-services-list {
        grid-template-columns: 1fr 1fr
    }

    .home-hero02-description p {
        padding: 38px 44px;
        width: 100%;
    }

    .home-content-01-section2 img {
        display: none;
    } 

    .home-content-01-section2 p {
        margin: 0;
        opacity: 1;
    }

    .home-content-01-section2 {
        margin-top: -100px;
    }

    .home-services-item-text {
        padding-right: 32px;
    }
    
    .home-services-item img {
        width: 240px;
        height: 240px;
    }
}

@media screen and (max-width: 768px) {
    .home-hero01 {
        height: 372px;
        background-position: center right;
        background-image: url('../../assets/hero01-sm.jpeg');
    }

    .home-hero01-01 {
        display: none;
    }

    .home-slider {
        display: none;
    }

    .home-slider-sm {
        display: block;
    }

    .home-services-container h1 {
        font-size: 24px;
    }

    .home-services-list {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .home-services-item img {
        display: none;
    }

    .home-services-item {
        background: none;
    }

    .home-location-container img {
        display: none;
    }
}