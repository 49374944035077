.daycare-button {
    background-color: white;
    border: 2px solid #04AA6D;
    color: #04AA6D;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
}

.daycare-button:hover {
    background-color: #04AA6D;
    color: white;
}

.daycare-intensivo-description {
    background-image: url('../../../assets/services/services_hero01.jpg');
    height: 650px;
    background-size: cover;
    background-position: center top;
}

.daycare-intensivo-description h3 {
    background-color: rgba(172, 93, 42, 0.7);
    font-size: 38px;
    font-weight: bold;
    color: white;
    padding: 20px 20px;
    width: 100%;
    margin: auto auto 0 0;
}