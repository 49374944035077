.footer {
    width: 100%;
    /* background-color: #ac5d2a; */
    background: linear-gradient(#ac5d2a, #882901);
}

.footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 32px 0;
    text-align: start;
}

.footer-logo img {
    width: 80px;
    border-radius: 50%;
}

.footer-links {
    display: flex;
    flex-direction: column;
    color: #fff;
}

.footer-contact {
    color: #fff;
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.footer-content-2 {
    width: 100%;
    background-color: #882901;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    color: #fff;
}

.footer_social_media:hover {
    cursor: pointer;
    color: #508104;
}

.footer-links a:hover {
    color: white;
}

@media screen and (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        padding: 18px 8%;
    }

    .footer-links, .footer-contact {
        margin-top: 18px;
        width: 100%;
        max-width: 328px;
    }

    .footer-content-2 p {
        text-align: center;
    }

    .contact-title {
        margin-top: 24px;
    }
}