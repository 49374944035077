.nosotros-container {
    display: flex;
    width: 100%;
}

.nosotros-content1 {
    width: 50%;
}

.nosotros-content1-grid {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.nosotros-content1-grid * {
    width: 100%;
}

.nosotros-content2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
}

.lg-image {
    display: block;
}

@media screen and (max-width: 1024px) {
    .nosotros-container {
        flex-direction: column-reverse;
    }

    .nosotros-content1, .nosotros-content2 {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .lg-image {
        display: none;
    }   
    .nosotros-content1-grid {
        grid-template-columns: 1fr 1fr;
        min-height: 412px;
    }
}