.site-header-spacer {
    width: 100%;
    height: 98px;
}

.site-header {
    /* background-color: #ac5d2a; */
    background: linear-gradient(#882901, #ac5d2a);
    position: fixed;
    top: 0;
    height: 98px;
    width: 100%;
    z-index: 1000000;
    box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
}

.site-header-content {
    height: 100%;
    width: 90%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px;
}

.site-header-logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.site-header-logo-container img {
    width: 60px;
    border-radius: 50%;
}

.site-header-logo-container h1 {
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    margin: 0px 24px;
}

.site-header-links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    paddding: 0 32px;
    display: block;
}

.site-header-links-item {
    color: #fff;
    display: inline-block;
    margin: 0 12px;
}

.site-header-mobile-menu {
    display: none;
}

.site-header-mobile-menu-items {
    width: 100%;
    position: fixed;
    background-color: #BA652E;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.site-header-links-item-mobile {
    color: #fff;
    display: inline-block;
    padding: 8px 12px;
    width: 100%;
    text-align: center;
    border-top: solid thin #E87E39;
}


@media screen and (max-width: 1024px) {
    .site-header-links-container {
        display: none;
    }

    .site-header-mobile-menu {
        display: block;
    }

    .site-header-logo-container h1 {
        font-size: 18px;
    }

    .site-header-logo-container img {
        width: 60px;
    }
}

.menu-icon {
  background-image: url('../../assets/menu-icon.svg');
  width: 50px;
  height: 50px;
}