.img-logo {
    height: 110px;
    width: 110px;
    margin: auto;
    object-fit: cover;
    border-radius: 150px;
    margin-bottom: 30px;
}

input:focus{
    border-color: #ac5d2a;
    border-width: 2px;
}

textarea:focus{
    border-color: #ac5d2a;
    border-width: 2px;
}

.contact-button {
    background-color: #ac5d2a;
}

.brown-bg {
    fill: #ac5d2a;
}