
.services-hero02-description {
    background-image: url('../../assets/services/campamento-canino.jpg');
    height: 632px;
    background-size: cover;
    background-position: center top;
}


.services-hero03-description {
    background-image: url('../../assets/services/club-canino01.jpg');
    height: 650px;
    background-size: cover;
    background-position: center top;
}

.services-hero03-description h3 {
    background-color: rgba(172, 93, 42, 0.7);
    font-size: 38px;
    font-weight: bold;
    color: white;
    padding: 20px 20px;
    width: 100%;
    margin: auto auto 0 0;
}


.services-hero02-description p {
    background-color: rgba(244, 238, 225, 0.7);
    color: #000000;
    font-weight: bold;
    padding: 38px 44px;
    width: 60%;
    margin: auto auto 0 0;
}

.services-hero04-description {
    background-image: url('../../assets/services/socializacion01.jpg');
    height: 540px;
    background-size: cover;
    background-position: center top;
}

.services-hero04-description p {
    background-color: rgba(244, 238, 225, 0.7);
    color: #000000;
    font-weight: bold;
    padding: 38px 44px;
    width: 100%;
    margin: auto auto 0 0;
}

.services-hero05-description {
    background-image: url('../../assets/services/socializacion03.jpg');
    height: 540px;
    background-size: cover;
    background-position: center top;
}

.services-hero05-description p {
    background-color: rgba(244, 238, 225, 0.7);
    color: #000000;
    font-weight: bold;
    padding: 38px 44px;
    width: 100%;
    margin: auto auto 0 0;
}

.services-hero06-description {
    background-image: url('../../assets/services/habilidades01.jpg');
    height: 540px;
    background-size: cover;
    background-position: center top;
}

.services-hero06-description p {
    background-color: rgba(244, 238, 225, 0.7);
    color: #000000;
    font-weight: bold;
    padding: 38px 44px;
    width: 100%;
    margin: auto auto 0 0;
}

.services-hero07-description {
    background-image: url('../../assets/services/servicio-intensivo01.jpg');
    height: 540px;
    background-size: cover;
    background-position: center top;
}

.services-hero07-description p {
    background-color: rgba(244, 238, 225, 0.7);
    color: #000000;
    font-weight: bold;
    padding: 38px 44px;
    width: 100%;
    margin: auto auto 0 0;
}

.services-hero08-description {
    background-image: url('../../assets/services/club-canino01.jpg');
    height: 540px;
    background-size: cover;
    background-position: center top;
}

.services-hero08-description p {
    background-color: rgba(244, 238, 225, 0.7);
    color: #000000;
    font-weight: bold;
    padding: 38px 44px;
    width: 100%;
    margin: auto auto 0 0;
}

.services-campamento-canino-02 {
    display: grid;
    background-color: rgba(244, 238, 225, 0.7);
    grid-template-columns: 1fr 1fr;
    /* justify-content: center;
    align-content: center; */
    margin: auto;
}

.services-campamento-info {
    margin: auto;
    padding: 42px 18px;
    max-width: 512px;
}

@media screen and (max-width: 1024px) {

    .services-hero02-description p {
        padding: 38px 44px;
        width: 100%;
    }
}

@media screen and (max-width: 768px) {

    .services-hero02-description, 
    .services-hero03-description, 
    .services-hero04-description, 
    .services-hero05-description, 
    .services-hero06-description,
    .services-hero07-description,
    .services-hero08-description {
        height: 432px;
    }
    
    .services-campamento-canino-02 {
        grid-template-columns: 1fr;
    }
}