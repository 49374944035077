.club-canino-hero-description {
    background-image: url('../../../assets/services/club-danino03.jpg');
    height: 650px;
    background-size: cover;
    background-position: center top;
}

.club-canino-hero-description h3 {
    background-color: rgba(172, 93, 42, 0.7);
    font-size: 38px;
    font-weight: bold;
    color: white;
    padding: 20px 20px;
    width: 100%;
    margin: auto auto 0 0;
}

.price-title {
    background-color: rgba(172, 93, 42, 0.7);
    font-size: 32px;
    font-weight: bold;
    color: white;
}