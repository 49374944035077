@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 

@font-face {
  font-family: 'adiestramiento-font';
     src: url('./assets/font/MADE-Tommy-Soft-Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'adiestramiento-font';
     src: url('./assets/font/MADE-Tommy-Soft-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'adiestramiento-font';
     src: url('./assets/font/MADE-Tommy-Soft-Light.otf');
  font-weight: 300;
}



body {
  margin: 0;
  font-family: 'adiestramiento-font', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.project-title {
  font-size: 48px;
  font-weight: bold;
  color: #ac5d2a;
}

.project-subtitle {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
}

p {
  font-size: 18px;
  font-weight: 400;
}

.btn-intensivo {
  background-color: white;
  border: 2px solid #ac5d2a;
  color: #ac5d2a;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.btn-intensivo:hover {
  background-color: #ac5d2a;
  color: white;

}

@media screen and (max-width: 1024px) {

  .project-title {
    font-size: 32px;
    font-weight: bold;
    color: #721a01;
  }

  .project-subtitle {
    font-size: 26px;
    font-weight: bold;
    color: #000000;
  }

  p {
    font-size: 17px;
    font-weight: 400;
  }
  
}

@media screen and (max-width: 768px) {

  .project-title {
    font-size: 26px;
    font-weight: bold;
    color: #ac5d2a;
  }

  .project-subtitle {
    font-size: 22px;
    font-weight: bold;
    color: #000000;
  }

  p {
    font-size: 16px;
    font-weight: 400;
  }
}