.services-intensivo-virtual-description {
    background-image: url('../../../assets/services/intensivoVirtual2.jpeg');
    height: 650px;
    background-size: cover;
    background-position: center top;
}

.services-intensivo-virtual-description h3 {
    background-color: rgba(172, 93, 42, 0.7);
    font-size: 38px;
    font-weight: bold;
    color: white;
    padding: 20px 20px;
    width: 100%;
    margin: auto auto 0 0;
}